// Generated by https://react-svgr.com/playground/
import * as React from 'react'

function SvgComponent (props) {
  return (
    <svg
      id='prefix__Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x={0}
      y={0}
      viewBox='0 0 498.5 164.7'
      xmlSpace='preserve'
      {...props}
    >
      <style>
        {
          '.prefix__st1{fill:#262626}.prefix__st2{fill:#fff}.prefix__st3{fill:#fff000}'
        }
      </style>
      <path fill='#b60028' d='M0 0h498.5v164.7H0z' />
      <path
        className='prefix__st1'
        d='M198.2 75.3c34.6 0 53.3 14.4 53.3 43.2 0 29.5-18.8 46.3-53.3 46.3-34.7 0-53.2-16.7-53.2-46.3 0-28.8 18.4-43.2 53.2-43.2zm0 64.8c8.1 0 9.2-9.2 9.2-21.7 0-10.2-1.2-18.6-9.2-18.6s-9 8.4-9 18.6c-.1 12.5.9 21.7 9 21.7zM302.2 68.2h-41.5V39.7h41.5v28.5zm-41.5 9h41.5v85.5h-41.5V77.2zM395.3 160.5c-9.2 2.9-18.4 4.3-30.8 4.3-34.7 0-53.1-16.7-53.1-46.3 0-28.7 18.4-43.2 53.1-43.2 12.3 0 21.5 1.1 30.1 3.6v25.3c-5.8-2.8-12.2-4.2-18.6-4.3-12.4 0-20.6 6.4-20.6 19.2 0 13.3 8.2 21 20.6 21 6.8.2 13.5-1.6 19.3-5.1v25.5zM441.2 129.9c.7 10.7 10.7 13.1 22.9 13.1 11.4 0 21.6-2 28.1-5.4v22.1c-11 3.4-24.2 4.9-38.7 4.9-34.7 0-53.1-16.7-53.1-46.3 0-28.7 18.4-43.2 53.1-43.2 33.2 0 44.9 20.7 44.9 49.6v5.1l-57.2.1zm21.4-18c0-6.6-1-16.9-10.4-16.9-9.5 0-11.7 10.2-11.2 16.9h21.6zM1 1h56l25.3 97.5h.4L108.3 1h53.4l-50.3 162.8H49.6z'
      />
      <path
        className='prefix__st2'
        d='M197.5 73.7c34.6 0 53.3 14.4 53.3 43.2 0 29.5-18.8 46.3-53.3 46.3-34.7 0-53.1-16.7-53.1-46.3-.1-28.8 18.4-43.2 53.1-43.2zm0 64.8c8.1 0 9.2-9.2 9.2-21.7 0-10.2-1.1-18.6-9.2-18.6s-9 8.4-9 18.6c-.1 12.5.9 21.7 9 21.7zM301.3 66.6h-41.5V38.1h41.5v28.5zm-41.5 9h41.5v85.5h-41.5V75.6zM394.4 158.9c-9.2 3-18.4 4.3-30.8 4.3-34.7 0-53.1-16.7-53.1-46.3 0-28.7 18.4-43.2 53.1-43.2 12.3 0 21.6 1.2 30.1 3.6v25.3c-5.8-2.8-12.2-4.2-18.6-4.3-12.3 0-20.6 6.4-20.6 19.2 0 13.3 8.2 21 20.6 21 6.8.2 13.4-1.6 19.2-5.1l.1 25.5zM440.3 128.4c.7 10.6 10.7 13.1 22.9 13.1 11.4 0 21.5-2 28.1-5.4v22.2c-11 3.4-24.2 4.9-38.7 4.9-34.7 0-53.1-16.7-53.1-46.3 0-28.7 18.4-43.2 53.1-43.2 33.2 0 44.9 20.7 44.9 49.6v5.1h-57.2zm21.4-18.1c0-6.6-1-16.9-10.4-16.9-9.5 0-11.7 10.2-11.2 16.9h21.6zM0 0h56l25.3 97.5h.4L107.3 0h53.4l-50.3 162.8H48.6z'
      />
      <path
        className='prefix__st3'
        d='M174.3 38c1.6.5 3 1.7 3.7 3.2.7 1.3 1 2.7 1 4.2 0 4.7-1.5 9.3-4.3 13-2.3 3.1-5.9 4.9-9.7 4.8h-11.1l5.5-25.9h11.1c1.3.1 2.6.3 3.8.7zm-10.7 3.9L160 58.8h5c2.5 0 4.6-1.3 6.1-3.8.9-1.5 1.5-3.2 1.9-4.9.6-2.6.6-4.6.1-6s-2-2.1-4.5-2.1l-5-.1zM191.2 37.4h6.1l3.6 25.9h-5.8l-.5-5.3H185l-2.9 5.3h-5.6l14.7-25.9zm-3.8 16.1h6.6l-1.1-10.2-5.5 10.2zM209.1 63.3h-5.4l5.5-25.9h5.4zM219.6 37.4h5.4l-4.5 21.2h12.7l-1 4.7h-18.1zM249.5 37.4h6.1l-12.1 16.1-2 9.8h-5.4l2.1-9.8-5.5-16.1h6.3l3 11.3z'
      />
    </svg>
  )
}

export default SvgComponent
