export function getFormFields(formElements) {
  var formElementsArray = [];
  for (var i in formElements) {
    var element = {};
    element.name = formElements[i].name;
    element.value = formElements[i].value;
    formElementsArray.push(element);
  }
  return formElementsArray.filter(function (element) {
    return element.name ? element.name.match('fields') !== 'null' : null;
  });
}

export function getAllFormFields(formElements) {
  var formEntries = {};
  for (var i = 0; i < formElements.length; i++) {
    if (formElements[i].type === 'checkbox') {
      if (!formEntries[formElements[i].name]) {
        formEntries[formElements[i].name] = [];
      }
      if (formElements[i].checked) {
        formEntries[formElements[i].name].push(formElements[i].value);
      }
    } else if (formElements[i].type === 'radio') {
      if (formElements[i].checked) {
        formEntries[formElements[i].name] = formElements[i].value;
      }
    } else {
      formEntries[formElements[i].name] = formElements[i].value;
    }
  }
  return formEntries;
}

function nodeScriptClone(node, src, callback) {
  if (!src && node.src) {
    return node;
  }
  if (src && !node.src) {
    return node;
  }
  var script = document.createElement('script');
  script.text = node.innerHTML;
  for (var i = node.attributes.length - 1; i >= 0; i--) {
    script.setAttribute(node.attributes[i].name, node.attributes[i].value);
  }
  if (callback) {
    return callback(script);
  }
  return script;
}

export function nodeScriptReplace(node, src, callback) {
  if (!node) {
    return;
  }
  if (node.tagName === 'SCRIPT') {
    try {
      node.parentNode.replaceChild(nodeScriptClone(node, src, callback), node);
    } catch (e) {
      console.error(e.message);
    }
  } else {
    var i = 0;
    var children = node.childNodes;
    while (i < children.length) {
      nodeScriptReplace(children[i++], src, callback);
    }
  }
}

export function executeScripts(parentNode) {
  var scripts = 0;
  if (parentNode) {
    nodeScriptReplace(parentNode, true, function (node) {
      scripts++;
      node.async = false;
      node.onload = function () {
        scripts--;
        if (scripts === 0) {
          // All scripts have been loaded
          nodeScriptReplace(parentNode, false);
        }
      };
      return node;
    });
  }
  if (scripts === 0) {
    // No loadable scripts, execute any javascript code
    nodeScriptReplace(parentNode, false);
  }
}