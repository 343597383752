import React, { Fragment, Component } from 'react'
import Helmet from 'react-helmet'

export class Klangoo extends Component {
  UNSAFE_componentWillMount () {
    if (typeof window !== 'undefined' && typeof __magnetLaunch === 'function') {
      __magnetLaunch() // eslint-disable-line no-undef
    }
  }

  render () {
    return (
      <>
        <Helmet>
          <script src='//magnetapi.klangoo.com/w/Widgets_320.js' type='text/javascript' async />
        </Helmet>
        <div className='related-articles'>
          <div data-widget-id={this.props.widgetId} />
        </div>
      </>
    )
  }
}

export default Klangoo
