import React, { Component } from 'react'
import Lightbox from 'react-18-image-lightbox'
import NoImage from '../static/no-image.png'
import PropTypes from 'prop-types'
import { ImageOrNoImage } from 'ion-image'
import { Link } from 'react-router-dom'
import 'react-18-image-lightbox/style.css'

export default class ArticleGallery extends Component {
  constructor (props) {
    super(props)
    this.state = {
      photoIndex: 0,
      isOpen: false
    }
  }

  render () {
    const gallery = this.props.gallery
    const width = this.props.width || 650
    const resizeURL = process.env.RAZZLE_RESIZE_URL + '/' + width + 'x' + width * 100 + '?source='
    return (
      <div className='article-gallery'>
        {gallery.map((image, index) => {
          return (
            <Link to='#' onClick={() => this.setState({ isOpen: true, photoIndex: index })} key={index}>
              <ImageOrNoImage image={image} width={200} shape='square' noImage={NoImage} alt={'Gallery image ' + index} />
            </Link>
          )
        })}
        {this.state.isOpen && (
          <div id='lighbox'>
            <Lightbox
              mainSrc={resizeURL + gallery[this.state.photoIndex].url}
              nextSrc={resizeURL + (gallery[(this.state.photoIndex + 1) % gallery.length].url)}
              prevSrc={resizeURL + (gallery[(this.state.photoIndex + gallery.length - 1) % gallery.length].url)}
              imageCaption={gallery[(this.state.photoIndex + gallery.length) % gallery.length].caption}
              onCloseRequest={() => this.setState({ isOpen: false })}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex: (this.state.photoIndex + gallery.length - 1) % gallery.length
                })}
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex: (this.state.photoIndex + 1) % gallery.length
                })}
            />
          </div>
        )}
      </div>
    )
  }
}

ArticleGallery.propTypes = {
  gallery: PropTypes.array.isRequired
}

ArticleGallery.defaultProps = {
  gallery: []
}
