import React, { useEffect, useRef } from 'react'
import { createRoot } from 'react-dom/client'
import videojs from 'video.js'
import 'videojs-contrib-ads'
import 'videojs-ima'
import 'video.js/dist/video-js.min.css'
import 'videojs-contrib-ads/dist/videojs.ads.css'
import 'videojs-ima/dist/videojs.ima.css'

import iolLogo from '../../app/images/brands/video/iol-wht'
import dvdLogo from '../../app/images/brands/video/daily-voice-red'
import anaLogo from '../../app/images/brands/video/ana-white'

const Logo = (props) => (
  <div className='ion-video-logo'>
    <svg className='ion-video-svg' width={props.width} height={props.height} viewBox={'0 0 ' + props.w + ' ' + props.h} dangerouslySetInnerHTML={{ __html: props.path }} />
  </div>
)

const Branding = {
  IndependentOnline: iolLogo,
  DailyVoice: dvdLogo,
  AfricanNewsAgency: anaLogo
}

const VideoPlayer = (props) => {
  const videoNode = useRef(null)
  let player = {}
  let brandingPath = ''
  let width = 40
  let height = 22
  let w = 501 // viewBox dimensions
  let h = 222
  let BrandingLogo = false
  if (props.branding && props.branding.logo) {
    width = props.branding.logo.tw || width
    height = props.branding.logo.th || height
    w = props.branding.logo.w || w
    h = props.branding.logo.h || h
  }

  if (props.branding && props.branding.logo && props.branding.logo.path) {
    brandingPath = props.branding.logo.path
    BrandingLogo = () => (<Logo width={width} height={height} w={w} h={h} path={brandingPath} />)
  } else if (props.channel) {
    if (Branding[props.channel]) {
      const ChannelBrandingLogo = Branding[props.channel]
      BrandingLogo = () => (
        <div className='ion-video-logo'>
          <ChannelBrandingLogo width={width} height={height} />
        </div>
      )
    }
  }

  useEffect(() => {
    player = videojs(videoNode.current, props)
    if (BrandingLogo) {
      const img = player.controlBar.addChild('Component', {})
      img.addClass('ion-video-logo')
      const logoRoot = createRoot(img.contentEl())
      logoRoot.render(<BrandingLogo />)
    }
    player.ima({
      id: videoNode.current,
      adLabel: 'Video Advertisement',
      adTagUrl: 'https://pubads.g.doubleclick.net/gampad/ads?sz=400x300|640x480&iu=/22491887924/IOL/Video&ciu_szs=300x250,300x600,728x90&impl=s&gdfp_req=1&env=vp&output=xml_vast2&unviewed_position_start=1'
      // adTagUrl: 'https://pubads.g.doubleclick.net/gampad/ads?sz=640x480&iu=/124319096/external/ad_rule_samples&ciu_szs=300x250&ad_rule=1&impl=s&gdfp_req=1&env=vp&output=xml_vmap1&unviewed_position_start=1&cust_params=sample_ar%3Dpremidpostpod%26deployment%3Dgmf-js&cmsid=496&vid=short_onecue&correlator='
    })
    return () => player.dispose
  }, [])

  return (
    <div data-vjs-player>
      <video ref={videoNode} className='video-js ion-video-js vjs-big-play-centered' width={props.width} />
    </div>
  )
}

export default VideoPlayer
