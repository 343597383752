import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { Articles, SectionMeta } from 'ion-article-cmp'
import { getSectionLabel } from 'ion-sections'
import Ad from '../components/Ad'
import { FORMATS, MAPPINGS } from '../components/AdFormats'
import Sidebar from '../components/Sidebar'
import NoImage from '../static/no-image.png'
import ArticleCard from '../components/ArticleCard'
import NotFound from './NotFound'
import WingBanners from '../components/WingBanners'

const AuthorArticles = ({ articles, sectionLabel, noImage, moreButton }) => {
  const title = 'Articles by ' + sectionLabel
  return (
    <>
      <Helmet title={title}>
        <meta property='og:title' content={title} />
        <meta itemProp='headline' content={title} />
        <meta name='twitter:title' content={title} />

      </Helmet>
      <h1>{title}</h1>
      {articles.map((article) => {
        return (
          <ArticleCard key={article.contentKey} article={article} noImage={noImage} />
        )
      })}
      {moreButton}
    </>
  )
}

const LeaderboardTop = [
  [[1024, 0], [[728, 90], [970, 90]]],
  [[640, 0], [[460, 60]]],
  [[0, 0], [[320, 100], [320, 50], [300, 100], [300, 50]]]
]

const Leaderboard = [
  [[1024, 0], [[728, 90], [970, 90], [970, 250]]],
  [[640, 0], [[460, 60]]],
  [[0, 0], [[300, 600], [336, 280], [320, 100], [320, 50], [300, 250], [300, 100], [300, 50]]]
]

const Wing = [
  [[1024, 0], [[160, 600], [120, 600]]],
  [[640, 0], []],
  [[0, 0], []]
]

const AuthorSectionPage = (props) => (
  <>
    <WingBanners useSmall={props.useSmall} isConnected={props.isConnected} location={props.location} onSlotRenderEndedLeft={props.onSlotRenderEndedLeft} onSlotRenderEndedRight={props.onSlotRenderEndedRight} mappingLeft={Wing} mappingRight={Wing} />
    <Helmet
      titleTemplate='%s'
      title={props.title || getSectionLabel(props.section)}
    >
      <meta property='og:type' content='section' />
      <meta name='description' content={props.description} />
      <meta itemProp='description' content={props.description} />
      <meta property='og:description' content={props.description} />
      <meta property='og:url' content={props.url} />
      <meta property='keywords' content={props.keywords} />
      <meta name='twitter:description' content={props.description} />
    </Helmet>
    <SectionMeta {...props} />
    <Ad isConnected={this.props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${props.section}`} slotId='interstitial' targeting={{ interstitial: 'interstitial' }} collapseEmptyDiv className='advert-leaderboard' anchor='interstitial' mapping={MAPPINGS.Interstitial} {...FORMATS.interstitial} />
    <Ad isConnected={props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${props.adPath || props.section}`} slotId='leaderboard-top' targeting={{ leaderboard: 'top' }} collapseEmptyDiv className='advert-leaderboard-top' mapping={LeaderboardTop} {...FORMATS.leaderboardtop} />
    <div className='wrapper article-listing'>
      {props.isPage0 &&
        <Articles {...props} component={AuthorArticles} section={props.section} count={15} pageSize={14} noImage={NoImage} notFound={NotFound} withMore />}
      {!props.isPage0 &&
        <Articles className='article-list paged' {...props} component={AuthorArticles} section={props.section} page0Limit={15} pageSize={14} noImage={NoImage} notFound={NotFound} withMore />}
      <Sidebar {...props} />
    </div>
    <Ad isConnected={props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${props.adPath || props.section}`} slotId='leaderboard-bot' targeting={{ leaderboard: 'bot' }} collapseEmptyDiv className='advert-leaderboard-bot' mapping={Leaderboard} {...FORMATS.leaderboard} />
  </>
)

AuthorSectionPage.defaultProps = {
  section: 'authors/molife-kumona'
}

export default AuthorSectionPage
