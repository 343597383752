import React, { Fragment } from 'react'
import ArticleCard from '../../components/ArticleCard'
import Ad from '../../components/Ad'
import { FORMATS } from '../../components/AdFormats'
import { Link } from 'react-router-dom'
import { getSectionLabel } from 'ion-sections'
import { Mobile, MobileElse } from 'ion-media'
import useHasMounted from '../../components/useHasMounted'
import Lyftitec from '../../components/Lyftitec'

const mpuRHS = [
  [[1024, 0], [[300, 250], [3, 3]]],
  [[640, 0], [[300, 250], [336, 280], [3, 3]]],
  [[0, 0], [[300, 600], [336, 280], [320, 100], [320, 50], [300, 250], [300, 100], [300, 50], [3, 3]]]
]

const Default = ({ articles, authorName, section, noImage, isConnected, sectionLabel, moreButton, relatedSections, withReadCount, adPath }) => {
  const hasMounted = useHasMounted()
  return (
    <>
      <h1><span>{sectionLabel}</span></h1>
      <div className='related-sections'>
        {relatedSections && (
          <>
            <p>Related Sections</p>
            <nav>
              <ul>
                {relatedSections.map((relatedSection) => {
                  return (
                    <li key={relatedSection.id}>
                      <Link to={'/' + relatedSection.id}>
                        {(relatedSection.label && (relatedSection.label)) || (getSectionLabel(relatedSection.id))}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </nav>
          </>
        )}
      </div>
      <div className='sections'>
        {articles.map((article, index) => {
          return (
            <Fragment key={article.contentKey}>
              {((index - 23) / 16) <= -1 && (
                <>
                  <Mobile>
                    <ArticleCard article={article} noImage={noImage} width={320} withReadCount={withReadCount} />
                  </Mobile>
                  {hasMounted &&
                    <MobileElse>
                      <ArticleCard article={article} noImage={noImage} width={325} withReadCount={withReadCount} />
                    </MobileElse>}
                </>
              )}
              {((index - 23) / 16) === -1 && (
                <Ad isConnected={isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${adPath || section}`} slotId='MPU-topright' targeting={{ MPU: 'topright' }} collapseEmptyDiv className='advert-mpu-right' mapping={mpuRHS} {...FORMATS.mpu} />
              )}
              {index === 9 && process.env.RAZZLE_ENABLE_LYTITEC &&
                <div className='advert-leaderboard '>
                  <Lyftitec />
                </div>}
              {((index - 23) / 16) > -1 && (
                <>
                  <Mobile>
                    <ArticleCard article={article} noImage={noImage} width={320} withReadCount={withReadCount} />
                  </Mobile>
                  {hasMounted &&
                    <MobileElse>
                      <ArticleCard article={article} noImage={noImage} width={325} withReadCount={withReadCount} />
                    </MobileElse>}
                </>
              )}
            </Fragment>
          )
        })}
      </div>
      {moreButton}
    </>
  )
}

export default Default
