import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const Lyftitec = () => {
  const location = useLocation()
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const script = document.createElement('script')
      script.defer = true
      script.async = true
      script.src = `${window.location.protocol}//a.vdo.ai/core/v-dailyvoice-co-za/vdo.ai.js`
      script.setAttribute('data-cfasync', 'false')

      document.head.appendChild(script)
      return () => {
        document.head.removeChild(script)
      }
    }
  }, [location])
  return (
    <div id='v-dailyvoice-co-za' />
  )
}

export default Lyftitec
