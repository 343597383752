import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Articles, SectionMeta } from 'ion-article-cmp'
import { getSectionLabel } from 'ion-sections'
import Ad from '../components/Ad'
import { FORMATS, MAPPINGS } from '../components/AdFormats'
import Sidebar from '../components/Sidebar'
import logo from '../static/general/daily-voice.jpg'
import NoImage from '../static/no-image.png'
import DefaultTemplate from '../templates/Articles/Default'
import NotFound from './NotFound'
import ArticleCard from '../components/ArticleCard'
import { MobileElse } from 'ion-media'
import useHasMounted from '../components/useHasMounted'
import WingBanners from '../components/WingBanners'

const LeaderboardTop = [
  [[1024, 0], [[728, 90], [970, 90]]],
  [[640, 0], [[460, 60]]],
  [[0, 0], [[320, 100], [320, 50], [300, 100], [300, 50]]]
]

const Leaderboard = [
  [[1024, 0], [[728, 90], [970, 90], [970, 250]]],
  [[640, 0], [[460, 60]]],
  [[0, 0], [[300, 600], [336, 280], [320, 100], [320, 50], [300, 250], [300, 100], [300, 50]]]
]

const Wing = [
  [[1024, 0], [[160, 600], [120, 600]]],
  [[640, 0], []],
  [[0, 0], []]
]

const DefaultSectionPage = (props) => {
  const hasMounted = useHasMounted()
  const Template = props.template || DefaultTemplate
  return (
    <>
      <WingBanners useSmall={props.useSmall} isConnected={props.isConnected} location={props.location} onSlotRenderEndedLeft={props.onSlotRenderEndedLeft} onSlotRenderEndedRight={props.onSlotRenderEndedRight} mappingRight={Wing} mappingLeft={Wing} />
      <Helmet
        titleTemplate='%s'
        title={props.title || getSectionLabel(props.section)}
      >
        <meta property='fb:app_id' content='293175074032541' />
        <meta property='og:type' content='section' />
        <meta property='og:title' content={props.title || getSectionLabel(props.section)} />
        <meta property='og:image' content={logo} />
        <meta itemProp='headline' content={props.title || 'Latest News from ' + getSectionLabel(props.section)} />
        <meta name='description' content={props.description} />
        <meta itemProp='description' content={props.description} />
        <meta property='og:description' content={props.description} />
        <meta property='og:url' content={props.url} />
        <meta property='keywords' content={props.keywords} />
        <meta name='twitter:site' content={props.twitterName} />
        <meta name='twitter:creator' content={props.twitterName} />
        <meta name='twitter:title' content={props.title || getSectionLabel(props.section)} />
        <meta name='twitter:description' content={props.description} />
        <meta itemprop='image' content={logo} />
        <meta itemprop='thumbnailUrl' content={logo} />
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:image:src' content={logo} />
      </Helmet>
      <SectionMeta {...props} />
      <Ad isConnected={props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${props.section}`} slotId='interstitial' targeting={{ interstitial: 'interstitial' }} collapseEmptyDiv className='advert-leaderboard' anchor='interstitial' mapping={MAPPINGS.Interstitial} {...FORMATS.interstitial} />
      <Ad isConnected={props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${props.adPath || props.section}`} slotId='leaderboard-top' targeting={{ leaderboard: 'top' }} collapseEmptyDiv className='advert-leaderboard' mapping={LeaderboardTop} {...FORMATS.leaderboardtop} />
      {props.isPage0 &&
        <Articles
          {...props} className='' component={(p) => (
            <div className='wrapper article-listing'>
              <div className='article-list section-page'>
                <Template {...p} />
              </div>
              <Sidebar {...props} />
            </div>
          )} section={props.section} sectionLabel={props.sectionLabel} count={17} pageSize={17} noImage={NoImage} notFound={NotFound} withMore
        />}
      {!props.isPage0 &&
        <Articles
          {...props} className='' component={(p) => (
            <div className='wrapper article-listing'>
              <div className='article-list paged'>
                <h1><span>{p.sectionLabel}</span></h1>
                {p.articles.map((article) => <ArticleCard key={article.contentKey} article={article} noImage={p.noImage} />)}
                {p.moreButton}
              </div>
              {/* <Sidebar {...props} /> */}
            </div>
          )} section={props.section} page0Limit={16} pageSize={16} noImage={NoImage} notFound={NotFound} withMore
        />}
      {hasMounted &&
        <MobileElse>
          <Ad isConnected={props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${props.adPath || props.section}`} slotId='leaderboard-bot' targeting={{ leaderboard: 'bot' }} collapseEmptyDiv className='advert-leaderboard' mapping={Leaderboard} {...FORMATS.leaderboard} />
        </MobileElse>}
    </>
  )
}

DefaultSectionPage.defaultProps = {
  component: DefaultTemplate,
  section: 'homepage'
}

export default connect((state) => ({ articles: state.articles }))(DefaultSectionPage)
