import React from 'react'
import { OutbrainWidget } from 'react-outbrain-widget'

export default class Outbrain extends React.Component {
  render () {
    return (
      <OutbrainWidget dataSrc={process.env.RAZZLE_SITE_URL + this.props.canonical} dataWidgetId='AR_1' />
    )
  }
}
